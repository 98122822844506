/* global Image, location, history */
import EventEmitter from 'events';
import forEach from 'lodash/forEach';
import _get from 'lodash/get';
import debug, { DEBUG } from '../../debug';
import { detachNodeAndPutItBackWhereItWas } from '../../DOMUtils';
import { IS_DEV, IS_TOP, BUILD_TIME } from '../../env';
import DLPSettings from '../../DLPSettings';
import reg, { PROP_INLINE_STYLE_CACHE } from '../DOMRegistry';
import api from './ClientAPI';
import {
    API_CLIENT_TO_BROWSER,
    ATTR,
    F_CHANGE,
    F_NODE,
    F_VALUE,
    REMOVED_ATTRIBUTE,
    COPY_ATTRIBUTE,
    TOP_FRAME_ID,
    REMOVED_NODE,
    FAKE_ROOT_ATTR_NAME, LOADING_TYPE
} from '../sharedConstants';
import * as DOM from '../DOM';
import * as RenderUtils from './RenderUtils';
import { decodeNodeType } from '../protocol';
import requestAnimationFrameOrElse from '../requestAnimationFrameOrElse';
import watchers, { checkedWatcher, selectedWatcher, valueWatcher } from '../watchers';
import mergeUpdate from '../mergeUpdates';
import Frame, { FRAME_REF_PROP } from './Frame';
import { messageToBrowser } from './browserMessageBus';
import selectionCtrl from './ClientSelectionController';
import { markNode } from './initCustomBehaviour';
import networkTimeoutCtrl from './NetworkTimeoutController';
import EventLogger from '../../ClientEventLogger';
import screenSizeController from './ScreenSizeController';
import { startPrint } from './printController';
import { renderCSS } from './CSSOM';
import CanvasDebugUI from './CanvasDebugUI';

const logger = debug.create('ClientPage', false);

// Notes on document init(in iframe)
// 1. change with doctype (aka 1st render)      --\
// 2. render of container node in parent frame  ---> frame is ready
// 3. assignment of iframe nodeId to frameId    --/

const INVALID_TAG_NAME = 'wl-invalid-tag';

const ATTR_ORIGINAL_SRC = '_src_';

const makeReadablePageUrl = (url) => `/page/url/${url}`;

function fixMedia(node) {
    if (IS_DEV) {
        node.crossOrigin = 'use-credentials';
    }
}

function processValueType(frame, values, valueName, processFn) {
    if (values[valueName] !== undefined) {
        Object.entries(values[valueName]).forEach(([id, value]) => processFn(frame, id, value, valueName));
    }
}

function preventAutofill(node) {
    node.readOnly = true;

    let timerId = 0;
    let onFocusCleanup = null;
    const cleanUp = () => {
        node.readOnly = false;

        reg.clearTimeout(node, timerId);
        reg.removeEventListener(node, 'focus', onFocusCleanup);
        delete node._cleanUp;
    };
    onFocusCleanup = () => {
        cleanUp();

        try {
            node.blur();
            node.focus();
        } catch (e) {
            debug.error(e);
        }
    };

    node._cleanUp = cleanUp;
    reg.addEventListener(node, 'focus', onFocusCleanup);
    timerId = reg.setTimeout(node, cleanUp, 1000);
}

export class ClientPage extends EventEmitter {
    EVENT_NEW_DOCUMENT = 'EVENT_NEW_DOCUMENT';
    onNewDocument = (...args) => this.on(this.EVENT_NEW_DOCUMENT, ...args);

    EVENT_AFTER_RENDER = 'EVENT_AFTER_RENDER';

    location = '';

    title = '';
    cssBlockRenderSet = new Set();
    frames = {};
    loadedLinksSet = new Set();
    // frame data waiting to be linked to the node
    linkFrameCbByNodeUid = {};

    // TODO: temp crutch
    frameNodeByDocumentId = {};

    /** @type Object<Number frameId, Object<Number frameVersion, Object change>> */
    queue = {};

    updateLoadingType(loadingType) {
        if (loadingType === LOADING_TYPE.CONTENT_LOAD) {
            messageToBrowser(API_CLIENT_TO_BROWSER.showIframe, {});
        }
        this.loadingType = loadingType;
    }

    isLoadTypeDetectElement() {
        return this.loadingType === LOADING_TYPE.DETECT_ELEMENT;
    }

    createNewFrame(frameId) {
        const frame = Frame.create(frameId);
        frame.loadingType = this.loadingType;
        frame.renderLock.onUnlock(() => setTimeout(() => this.dispatchChanges(frameId)));
        return frame;
    }

    /** @returns {Frame} */
    getFrameById = (frameId) => Frame.get(frameId) || this.createNewFrame(frameId);

    /** @returns {Frame} */
    resetFrame(frameId, documentId, frameVersion) {
        const frame = this.getFrameById(frameId);
        // first update for top frame will have a documentId
        if (frameId === 0) {
            frame.frameVersion = Math.max(frame.frameVersion, frameVersion);
            return frame;
        }

        let newFrame;
        if (frame.documentId && frame.documentId !== documentId) {
            frame.destroy();
            newFrame = this.createNewFrame(frameId);
        } else {
            newFrame = frame;
        }
        newFrame.documentId = documentId;
        newFrame.frameVersion = frameVersion;
        if (this.frameNodeByDocumentId[documentId] !== undefined) {
            newFrame.setFrameNode(this.frameNodeByDocumentId[documentId]);
            delete this.frameNodeByDocumentId[documentId];
        }
        return newFrame;
    }

    addFrameDocument(frameId, documentObject, realDoc = null) {
        // logger.info('FRAME add document', frameId, documentObject);
        const frame = this.getFrameById(frameId);
        if (frame.doc === documentObject) {
            return;
        }
        frame.doc = documentObject;
        frame.realDoc = realDoc;
        this.dispatchChanges(frameId);
        this.emit(this.EVENT_NEW_DOCUMENT, frame);
    }

    linkIframeNodeIdToFrame = (frame, nodeId, [frameId, documentId]) => {
        // logger.info('FRAME link node id', frameId, documentId, nodeId);
        const node = reg.getNode(frame.id, nodeId);
        if (node) {
            this.linkIframeNodeToFrame(frameId, node, documentId);
        } else {
            const uid = reg.getUid(node);
            this.linkFrameCbByNodeUid[uid] = (newNode) => {
                delete this.linkFrameCbByNodeUid[uid];
                // RAF is important: iframe might be in process of rendering, therefore not in DOM yet
                // or it might be moved in DOM, causing reloading
                requestAnimationFrameOrElse(() => this.linkIframeNodeToFrame(frameId, newNode, documentId));
            };
        }
    };

    linkIframeNodeToFrame(frameId, iframeNode, documentId) {
        // logger.info('FRAME link node', frameId, documentId, iframeNode);
        if (!iframeNode || !documentId) {
            debug.warn('linkIframeNodeToFrame called with bad args', frameId, iframeNode, documentId);
            return;
        }

        const frame = this.getFrameById(frameId);
        // TODO: fix by separating frameNode from Frame objects
        // consider following
        // 0. frame is rendered with (e.g. frameId = 42, documentId = 1, frameNode = A)
        // 1. linkIframeNodeToFrame (42, A, 2)
        // 2. init data comes
        // 3. resetFrame(42, 2)
        // link of frameNode is lost because it was created before frame reset
        if (frame.documentId === documentId) {
            // this may happen only if website re-inserted same iframe in the same place in DOM
            if (iframeNode[FRAME_REF_PROP] && iframeNode[FRAME_REF_PROP] !== frame) {
                iframeNode[FRAME_REF_PROP].destroy();
            }
            frame.setFrameNode(iframeNode);
            this.maybeInitFrame(frameId);
        } else {
            this.frameNodeByDocumentId[documentId] = iframeNode;
        }
    }

    maybeInitFrame(frameId) {
        const frame = this.getFrameById(frameId);

        if (!frame.iframeNode || !frame.hasInitData) {
            return;
        }

        // TODO: figure out why server sends linkIframeNodeToFrame twice
        if (frame.iframeNode._documentId === frame.documentId) {
            debug.warn('maybeInitFrame: iframe is already read', frameId);
            return;
        }

        // frames without src attribute cause undefined behaviours and bugs
        if (frame.iframeNode._documentId) {
            detachNodeAndPutItBackWhereItWas(frame.iframeNode);
        }
        frame.iframeNode.src = 'about:blank';
        frame.iframeNode._documentId = frame.documentId;

        const doc = frame.iframeNode.contentDocument;
        if (!doc) {
            // TODO: figure out why
            debug.warn('iframe has no document!', frame, frame.iframeNode.isConnected);
            return;
        }
        doc.open('text/html', 'replace'); // important for firefox
        /* eslint-disable import/no-unresolved, global-require, import/no-webpack-loader-syntax, import/no-extraneous-dependencies */
        doc.write(`${frame.doctype}<html><!--${frameId} ${frame.documentId}--><head>${frame.head}</head></html>`);
        /* eslint-enable import/no-unresolved, global-require, import/no-webpack-loader-syntax, import/no-extraneous-dependencies */
        doc.close();
        doc.documentElement.setAttribute(FAKE_ROOT_ATTR_NAME, true);
        this.addFrameDocument(frameId, doc);
        this.registerPrerenderedNodes(frameId);
    }

    registerPrerenderedNodes(frameId) {
        const frame = this.getFrameById(frameId);
        if (frame.doc && frame.doc.head) {
            Array.from(frame.doc.querySelectorAll(`[${ATTR.PRERENDERED_ID}]`))
                .forEach((node) => {
                    if (node && node.getAttribute) {
                        const nodeId = node.getAttribute(ATTR.PRERENDERED_ID);
                        if (nodeId) {
                            node.removeAttribute(ATTR.PRERENDERED_ID);
                            reg.addNode(frameId, node, nodeId);
                            RenderUtils.debugNode(node);
                        }
                    }
                });
        }
    }

    addChanges = (changes) => forEach(changes, this.addChange);

    addChange = (change) => {
        const frameId = change[F_CHANGE.frameId];
        const frameVersion = change[F_CHANGE.frameVersion];
        const documentId = change[F_CHANGE.documentId];
        const frame = this.getFrameById(frameId);

        if (frameVersion >= frame.frameVersion) {
            // logger.info('add change', frameId, change);
            if (documentId !== undefined) {
                this.resetFrame(frameId, documentId, frameVersion);
            }

            if (change[F_CHANGE.init] !== undefined) {
                // logger.info('FRAME init data', frameId, change[F_CHANGE.init].documentId);
                this.getFrameById(frameId).setInitData(change[F_CHANGE.init]);
                this.maybeInitFrame(frameId);
            }
        }

        this.queue[frameId] = this.queue[frameId] || {};
        this.queue[frameId][frameVersion] = this.queue[frameId][frameVersion] || {};
        this.queue[frameId][frameVersion][change[F_CHANGE.id]] = change;

        // TODO: maybe frameVersion check
        this.dispatchChanges(frameId);

        if (change[F_CHANGE.domContentLoadedInServerBrowser]) {
            frame.needToWaitToBodyTag = false;
            if (frame.isReadyToPaint()) {
                frame.show();
            }
        }
    };

    dispatchChanges(frameId) {
        const frame = this.getFrameById(frameId);
        if (frame.doc // document in place
            && !frame.dispatchIsScheduled // duplicate call
            && !frame.renderLock.isLocked() // rendering is not frozen
        ) {
            frame.dispatchIsScheduled = true;
            requestAnimationFrameOrElse(() => {
                frame.dispatchIsScheduled = false;
                if (!frame.doc || frame.renderLock.isLocked()) {
                    return;
                }

                networkTimeoutCtrl.onBeforeRendering();
                requestAnimationFrameOrElse(networkTimeoutCtrl.onAfterRendering);
                this.renderChanges(frameId);
            });
        }
    }

    renderChanges(frameId) {
        EventLogger.mark(EventLogger.EVENT_CLIENT_RENDER);
        const frame = this.getFrameById(frameId);

        const changeIdsToDispatch = [];
        let nextChangeId = frame.nextChangeId;
        let actionCounter;
        const queue = _get(this.queue, [frameId, frame.frameVersion], {});
        while (queue[nextChangeId]) {
            const ac = queue[nextChangeId][F_CHANGE.actionCounter];
            if (ac !== undefined && (!actionCounter || actionCounter < ac)) {
                actionCounter = ac;
            }
            changeIdsToDispatch.push(nextChangeId);
            nextChangeId += 1;
        }

        if (changeIdsToDispatch.length === 0) { // empty sequence - no actions
            return;
        }

        // if at least one change in sequence depends on actionCounter
        // which is less than current actionCounter - block whole sequence
        if (actionCounter !== undefined && actionCounter < frame.actionCounter) {
            return;
        }

        // merge changes
        const change = changeIdsToDispatch.map((id) => queue[id]).reduce(mergeUpdate, {});

        // logger.group(`Render change${
        //     changeIdsToDispatch.length === 1
        //         ? ' ' + changeIdsToDispatch[0]
        //         : 's ' + changeIdsToDispatch[0] + '-' + changeIdsToDispatch[changeIdsToDispatch.length - 1]
        // } on frame#${frameId}`, change, true);

        // cleanup changes from memory
        if (!DEBUG) {
            Object.keys(this.queue[frameId])
                .filter((frameVersion) => frameVersion < frame.frameVersion)
                .forEach((frameVersion) => delete this.queue[frameId][frameVersion]);

            changeIdsToDispatch.forEach((changeId) => delete this.queue[frameId][frame.frameVersion][changeId]);
        }

        frame.renderLock.lock();
        frame.textWatcherLock.lock();
        requestAnimationFrameOrElse(() => {
            frame.renderLock.unlock();
            frame.textWatcherLock.unlock();
        });

        // focus document on first render
        if (!frame.initialRenderingIsDone && frame.isTop && this.couldSetSelection()) {
            logger.log('focus top document on first render');
            DOM.focusDocument(frame.doc);
        }

        const values = change[F_CHANGE.values];
        const selectionBeforeRender = DOM.getSelection(frame.doc);

        if (values !== undefined) {
            processValueType(frame, values, F_VALUE.dict, this.onNewValueDict);
            processValueType(frame, values, F_VALUE.blob, this.onNewValueBlob);
        }

        if (change[F_CHANGE.media] !== undefined) {
            forEach(change[F_CHANGE.media], (item) => frame.mediaRepository.onAction(item));
        }

        if (change[F_CHANGE.viewport] !== undefined && frameId === TOP_FRAME_ID) {
            screenSizeController.setViewport(change[F_CHANGE.viewport]);
        }

        // cleanup nodes
        if (change[F_CHANGE.deepCleanUp] !== undefined) {
            change[F_CHANGE.deepCleanUp].forEach((nodeId) => RenderUtils.cleanUpNode(reg.getNode(frameId, nodeId)));
        }

        // destroy frames BEFORE removing them from DOM.
        // When you remove frame from DOM — you're losing reference to the window object of that frame
        // it may cause user visible NPE's in IE11
        // (product will work fine after such NPE, but user may see a error popup or browser crash sometimes)
        if (change[F_CHANGE.removedFrameNodeIds] !== undefined) {
            this.destroyFramesByNodeId(frameId, change[F_CHANGE.removedFrameNodeIds]);
        }

        // render DOM diff
        if (change[F_CHANGE.dom] !== undefined) {
            this.renderDOM(frameId, change[F_CHANGE.dom]);
        }

        // register dummy custom elements to make css selector :defined work
        if (change[F_CHANGE.customElements] !== undefined) {
            this.processCustomElements(frame, change[F_CHANGE.customElements]);
        }

        // apply CSS method calls
        if (change[F_CHANGE.css] !== undefined) {
            renderCSS(frame, change[F_CHANGE.css]);
        }

        if (values !== undefined) {
            // watchers
            // order of volume and playback watchers is important
            // since in some browsers (e.g. safari) you can start auto play video if it has volume 0
            processValueType(frame, values, F_VALUE.volume, this.setWatcherValue);
            processValueType(frame, values, F_VALUE.playback, this.setWatcherValue);
            processValueType(frame, values, F_VALUE.value, this.setWatcherValue);
            processValueType(frame, values, F_VALUE.checked, this.setWatcherValue);
            processValueType(frame, values, F_VALUE.selected, this.setWatcherValue);
            processValueType(frame, values, F_VALUE.scroll, this.setWatcherValue);

            // values
            processValueType(frame, values, F_VALUE.documentElementId, this.setDocumentElement);
            processValueType(frame, values, F_VALUE.frameId, this.linkIframeNodeIdToFrame);
            processValueType(frame, values, F_VALUE.src, this.onNewValueSrc);
            processValueType(frame, values, F_VALUE.designMode, this.onNewValueDesignMode);
            processValueType(frame, values, F_VALUE.customBehaviour, this.onCustomBehaviour);
            processValueType(frame, values, F_VALUE.adoptedStyleSheets, this.onAdoptedStyleSheets);
        }

        // update canvases
        if (change[F_CHANGE.canvas] !== undefined) {
            Object.keys(change[F_CHANGE.canvas])
                .forEach((nodeId) => this.updateCanvas(frame, nodeId, change[F_CHANGE.canvas][nodeId]));
        }

        // set user selection
        if (change[F_CHANGE.selection] && this.couldSetSelection()) {
            selectionCtrl.renderSelection(frame, change[F_CHANGE.selection], selectionBeforeRender);
        }

        // propagate title change on the top frame to Browser UI
        if (frame.isTop) {
            this.checkTitle();
        }

        if (values) {
            processValueType(frame, values, F_VALUE.startPrint, () => startPrint(frame));
        }

        EventLogger.measureAndSend(
            EventLogger.EVENT_CLIENT_RENDER,
            { changesCount: nextChangeId - frame.nextChangeId, isFirstRender: frame.nextChangeId === 1, frameId },
            undefined,
            1000
        );

        frame.nextChangeId = nextChangeId;

        if (!frame.initialRenderingIsDone) {
            frame.initialRenderingIsDone = true;
            debug.addDebugAttribute(frame.doc, 'weblife-init-done', 'true');

            if (frame.isTop) {
                messageToBrowser(API_CLIENT_TO_BROWSER.firstRenderFinished, {
                    type: 'page',
                    pageId: EventLogger.context.pageId,
                    browserId: EventLogger.context.browserId,
                    serverBrowserVersion: BUILD_TIME,
                    customMetadata: EventLogger.context.customMetadata || {},
                });
            }
        }
    }

    onNewValueDict = (frame, id, value) => {
        if (frame.dict[id] !== undefined) {
            debug.warn('word is already registered in the dictionary', { frame, id, value });
        }
        frame.dict[id] = value;
    };

    onNewValueBlob = (frame, blobUrl, { type, data }) => {
        if (type === 'mediaSource') {
            const node = frame.nodeByOriginalSrc[blobUrl];
            if (node) {
                frame.mediaRepository.captureStreamForId(data.id, node);
            } else {
                frame.mediaRepository.associateMediaWithBlobUrl(data.id, blobUrl);
            }
        }
    };

    onNewValueSrc = (frame, id, value) => {
        const node = reg.getNode(frame.id, id);
        if (node) {
            node.src = value;
        } else {
            debug.warn('missing target for src value', { frame, id, value });
        }
    };

    onNewValueDesignMode = (frame, id, value) => {
        const { doc } = frame;
        if (doc) {
            doc.designMode = value;
        } else {
            debug.warn('missing target for designMode value', { frame, id, value });
        }
    };

    onCustomBehaviour = (frame, id, value) => {
        const node = reg.getNode(frame.id, id);
        if (node && value) {
            value.forEach((label) => markNode(node, label));
        } else {
            debug.warn('onCustomBehaviour missing args', { frame, id, value, node });
        }
    };

    /**
     * @param {Frame} frame
     * @param {String} id
     * @param {Array<number>} cssIds
     */
    onAdoptedStyleSheets = (frame, id, cssIds) => {
        const node = id === '0' ? frame.doc : reg.getNode(frame.id, id);
        if (node && cssIds) {
            node.adoptedStyleSheets = cssIds.map(frame.getCssStyleSheet).filter(Boolean);
        } else {
            debug.warn('onAdoptedStyleSheets missing args', { frame, id, cssIds, node });
        }
    };

    /**
     * @param {Frame} frame
     * @param {Array<string>} elementNames
     */
    processCustomElements = (frame, elementNames) => {
        elementNames?.forEach?.((elementName) => {
            try {
                const win = frame.doc.defaultView;
                win.customElements.define(elementName, class Noop extends win.HTMLElement {
                    // eslint-disable-next-line no-useless-constructor
                    constructor() {
                        super();
                    }
                });
            } catch (e) {
                logger.warn('processCustomElements failed to register custom element', { frame, elementName });
            }
        });
    };

    setDocumentElement = (frame, id, newDocElementId) => {
        const { doc } = frame;
        if (!newDocElementId || !doc) {
            return;
        }

        const currentDocElement = doc.documentElement;
        const currentDocElementId = reg.getNodeId(doc.documentElement);

        if (newDocElementId === currentDocElementId) {
            return;
        }

        const newDocumentElement = reg.getNode(frame.id, newDocElementId);
        if (newDocumentElement) {
            if (newDocumentElement !== currentDocElement) {
                debug.log(`setDocumentElement frame#${frame.id}: replacing document root`, {
                    currentDocElementId,
                    currentDocElement,
                    newDocElementId,
                    newDocumentElement,
                });
                try {
                    doc.replaceChild(newDocumentElement, currentDocElement);

                    // transition debug attributes from the old root to the new one
                    Array.from(currentDocElement.attributes).forEach((attr) => {
                        if (attr && attr.name && attr.name.startsWith('weblife-')) {
                            debug.addDebugAttribute(doc, attr.name, attr.value);
                        }
                    });
                    newDocumentElement.setAttribute(FAKE_ROOT_ATTR_NAME, true);
                } catch (e) {
                    debug.warn('setDocumentElement failed to replace documentElement', e);
                }
            }
        } else {
            debug.warn(`setDocumentElement frame#${frame.id} missing new documentElement`, { frame, newDocElementId });
        }
    };

    setWatcherValue = (frame, id, value, type) => {
        const watcher = watchers.byName[type];
        if (watcher) {
            const node = reg.getNode(frame.id, id);
            if (node) {
                if (!watcher.isWatching(node)) {
                    watcher.register(node);
                }

                if (watcher.debug) {
                    debug.info('watcher setValue', type, frame.id, id, value);
                }
                watcher.setValue(node, reg.getUid(node), value);
            } else {
                debug.warn('no node for watcher', watcher.name, id);
            }
        }
    };

    updateCanvas(frame, nodeId, update) {
        const frameId = frame.id;
        const actionId = update.data.actionId;
        const recordCanvasDebugEvent = CanvasDebugUI.isCanvasDebuggingEnabled()
            ? api.recordImportantEvent
            : () => {};

        recordCanvasDebugEvent('Client Canvas: update notification received', {
            frameId,
            nodeId,
            actionId,
            incomingImageSize: update.data.size,
            executionId: update.data.executionId,
        });
        const node = reg.getNode(frame.id, nodeId);
        if (!node) {
            return;
        }

        node._lastRenderedFrameId = node._lastRenderedFrameId || 0;

        if (node._lastActionId >= actionId) {
            return;
        }

        node._lastActionId = actionId;

        if (api.didConnectedOnce && !api.isConnected) {
            return;
        }

        const img = new Image();

        const drawDurationStart = Date.now();
        const onLoad = () => {
            if (node._lastRenderedFrameId > actionId) {
                recordCanvasDebugEvent('Client Canvas: Skip image', {
                    frameId: frame.id,
                    nodeId,
                    actionId,
                    lastFrameId: node._lastRenderedFrameId,
                    executionId: update.data.executionId,
                });
                return;
            }

            node._lastRenderedFrameId = actionId;

            const width = Math.max(1, Math.floor(node.width));
            const height = Math.max(1, Math.floor(node.height));
            img.width = width;
            img.height = height;

            try {
                const context = node.getContext('2d');
                context.clearRect(0, 0, width, height);
                context.drawImage(img, 0, 0, width, height);

                CanvasDebugUI.getInstance().handleDebugUI({
                    frameId,
                    context,
                    update,
                    nodeId,
                    node,
                    executionId: update.data.executionId,
                });

                recordCanvasDebugEvent('Client Canvas: image drawn', {
                    frameId,
                    nodeId,
                    actionId,
                    ms: Date.now() - drawDurationStart,
                    executionId: update.data.executionId,
                    reason: update.data.reason,
                    quality: update.data.quality,
                });
            } catch (e) {
                debug.recordError('canvas drawImage failed', {
                    frameId,
                    nodeId,
                    tagName: node.tagName,
                    error: e.message || e,
                    executionId: update.data.executionId,
                });
            }
        };

        img.addEventListener('load', onLoad);
        window.fetch(update.data.src, {
            credentials: 'include',
        })
            .then((response) => response.blob())
            .then((blob) => {
                update.data.size = blob.size;
                img.src = URL.createObjectURL(blob);
            })
            .catch((error) => debug.recordError('Error fetching image:', error));
    }

    destroyFramesByNodeId(frameId, removedFrameNodeIds) {
        removedFrameNodeIds.forEach((nodeId) => {
            const frameNode = reg.getNode(frameId, nodeId);

            if (frameNode && frameNode[FRAME_REF_PROP]) {
                frameNode[FRAME_REF_PROP].destroy();
            }
        });
    }

    isNeedToHandleABlockRenderNode(frame, node) {
        if (frame.needToWaitToBodyTag && frame.isTop &&
            this.isLoadTypeDetectElement() && DOM.isBlockerCssLinkNode(node) &&
            !this.cssBlockRenderSet.has(node.href)) {
            const loadedLinks = Array.from(document.styleSheets)
                .map((a) => a?.href).filter(Boolean);
            loadedLinks.forEach((link) => {
                this.loadedLinksSet.add(link);
            });
            if (!this.loadedLinksSet.has(node.href)) {
                this.cssBlockRenderSet.add(node.href);
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    handleNewBlockRender(frame, node) {
        frame.updateCssBlockRender(false);
        node.onload = () => {
            frame.updateCssBlockRender(true);
        };
        node.onerror = () => {
            frame.updateCssBlockRender(true);
        };
    }

    renderDOM(frameId, dom) {
        const frame = this.getFrameById(frameId);

        valueWatcher.checkAllNodes();
        checkedWatcher.checkAllNodes();
        selectedWatcher.checkAllNodes();

        const newElements = [];
        const deletedElementUids = [];
        const shadowRoots = [];

        // create new, delete old, and modify changed nodes
        forEach(dom, (data, id) => {
            let node = reg.getNode(frameId, id);
            // deleted
            if (data === REMOVED_NODE) {
                if (node && node.parentNode) {
                    if (frame.doc && (node === frame.doc.body || node === frame.doc.head
                        || node === frame.doc.documentElement)) {
                        debug.info('cleaning up node instead of removing', node);
                        // remove only unknown nodes, such as CE generated or inserted by extensions
                        // every other node will be deleted renderDOM call
                        RenderUtils.cleanUpNode(node);
                    } else {
                        node.parentNode.removeChild(node);
                    }

                    if (DOM.isElementNode(node)) {
                        deletedElementUids.push(reg.getUid(node));
                    }

                    if (node.getAttribute?.(ATTR_ORIGINAL_SRC)) {
                        delete frame.nodeByOriginalSrc[node.getAttribute(ATTR_ORIGINAL_SRC)];
                    }

                    reg.removeNode(node);
                }
                return;
            }

            // new node
            if (data[F_NODE.HOST_ID]) {
                shadowRoots.push({ hostId: data[F_NODE.HOST_ID], nodeId: id });
            } else if (!node) {
                node = this.createNode(frame, data);

                if (node) {
                    if (frame.needToWaitToBodyTag &&
                        this.isLoadTypeDetectElement() && node.tagName === DOM.TAG_BODY) {
                        // Head is completed in the first render => No worries about FOUC
                        frame.needToWaitToBodyTag = false;
                        if (frame.isReadyToPaint()) {
                            frame.show();
                        }
                    }

                    reg.addNode(frameId, node, id);

                    if (DOM.isElementNode(node)) {
                        newElements.push(node);

                        const uid = reg.getUid(node);
                        if (this.linkFrameCbByNodeUid[uid]) {
                            this.linkFrameCbByNodeUid[uid](node);
                        }

                        RenderUtils.debugNode(node);

                        // TODO: refactor
                        if (DOM.isImageNode(node)) {
                            node.hasError = false;
                            node.finished = false;

                            node.addEventListener('load', () => {
                                node.hasError = false;
                                node.finished = true;
                            });

                            node.addEventListener('error', () => {
                                node.hasError = true;
                                node.finished = true;
                            });
                        }

                        // prevent auto-filling
                        if (DOM.isElementOneOf(node, DOM.TAG_INPUT, DOM.TAG_TEXTAREA)) {
                            preventAutofill(node);
                        }
                    }
                } else {
                    debug.error('cannot create node', id, data, dom);
                    return;
                }
            } else if (data[F_NODE.TEXT] !== undefined) {
                if (node.nodeValue !== data[F_NODE.TEXT]) { // only if textContent changed
                    try {
                        node.nodeValue = data[F_NODE.TEXT];
                    } catch (e) {
                        debug.warn(`failed to set nodeValue on node${id}:
                                    new value='${data[F_NODE.TEXT]}',
                                    old value='${node.nodeValue}'`, node.detached, e);
                        reg.removeNode(node);
                        node = this.createNode(frame, data);
                        if (node) {
                            reg.addNode(frameId, node, id);
                        }
                    }
                }
            }

            // set attributes
            if (data[F_NODE.ATTR] !== undefined) {
                // TODO: optimize loops
                const attrs = Object.entries(data[F_NODE.ATTR])
                    .reduce((decodedAttrs, [attrNameId, attrValue]) => {
                        decodedAttrs[frame.dict[attrNameId]] = attrValue;
                        return decodedAttrs;
                    }, {});
                const attrsNS = Object.entries(data[F_NODE.ATTR_NS] || {})
                    .reduce((decodedAttrsNs, [attrNameId, attrNsValueId]) => {
                        decodedAttrsNs[frame.dict[attrNameId]] = frame.dict[attrNsValueId];
                        return decodedAttrsNs;
                    }, {});

                if (attrs[ATTR_ORIGINAL_SRC] === COPY_ATTRIBUTE) {
                    attrs[ATTR_ORIGINAL_SRC] = attrs.src;
                }

                if (data[F_NODE.TAG] !== undefined) {
                    // data[F_NODE.TAG] implies that node was either just created
                    // or requested to be re-indexed by contenteditable logic
                    // in the latter case node already exists on client and might have some attributes
                    // data[F_NODE.ATTR] represents ALL attributes node should have
                    // therefore we delete attributes not in data[F_NODE.ATTR]
                    Array.from(node.attributes).forEach((attr) => {
                        if ((attrs[attr.name] === undefined) && !RenderUtils.isDebugAttr(attr.name)) {
                            DOM.setAttribute(node, attr.name, REMOVED_ATTRIBUTE, attr.namespaceURI);
                        }
                    });
                }

                if (attrs.type) { // set 'type' attribute before others for IE11
                    DOM.setAttribute(node, 'type', attrs.type);
                    delete attrs.type;
                }

                if (attrs.readonly !== undefined && node._cleanUp) {
                    node._cleanUp();
                }

                Object.keys(attrs).forEach((attrName) =>
                    DOM.setAttribute(node, attrName, attrs[attrName], attrsNS[attrName]));

                if (this.isNeedToHandleABlockRenderNode(frame, node)) {
                    this.handleNewBlockRender(frame, node);
                }

                if (attrs[ATTR_ORIGINAL_SRC]) {
                    frame.nodeByOriginalSrc[attrs[ATTR_ORIGINAL_SRC]] = node;
                    frame.mediaRepository.captureStreamForBlobURL(attrs[ATTR_ORIGINAL_SRC], node);
                }
            }

            if (data[F_NODE.STYLE] === REMOVED_ATTRIBUTE) {
                node[PROP_INLINE_STYLE_CACHE] = null;
                DOM.setAttribute(node, 'style', REMOVED_ATTRIBUTE);
            } else if (data[F_NODE.STYLE] !== undefined) {
                const { css, values, order } = data[F_NODE.STYLE];
                if (css !== undefined) {
                    node[PROP_INLINE_STYLE_CACHE] = null;
                    DOM.setAttribute(node, 'style', css);
                } else if ((values !== undefined) || (order !== undefined)) {
                    const { values: prevValues = {}, order: prevOrder = [] } = node[PROP_INLINE_STYLE_CACHE] || {};
                    const newValues = Object.assign(prevValues, values);
                    const newOrder = order || prevOrder;
                    node[PROP_INLINE_STYLE_CACHE] = { values: newValues, order: newOrder };
                    const styleValue = newOrder
                        .map((propId) => `${frame.dict[propId]}:${newValues[propId]}`)
                        .join(';');
                    DOM.setAttribute(node, 'style', styleValue);
                }
            }

            // detach children
            if (data[F_NODE.CHILDREN] !== undefined && !DOM.isElementOneOf(node, DOM.TAG_STYLE)) {
                for (let i = 0; i < data[F_NODE.CHILDREN].length; i += 1) {
                    const childNode = reg.getNode(frameId, data[F_NODE.CHILDREN][i]);
                    if (childNode && childNode.parentNode !== node) {
                        DOM.removeNode(childNode);
                    } else if (childNode && this.isNeedToHandleABlockRenderNode(frame, childNode)) {
                        this.handleNewBlockRender(frame, childNode);
                    }
                }
            }

            if (data[F_NODE.TEXT_CONTENT]) {
                node.textContent = data[F_NODE.TEXT_CONTENT];
            }
        });

        // attach shadowRoots
        const newShadowRootNodeIds = [];
        shadowRoots.forEach(({ hostId, nodeId }) => {
            const hostNode = reg.getNode(frameId, hostId);
            if (hostNode) {
                let shadowRoot = hostNode.shadowRoot;
                if (!shadowRoot && hostNode.attachShadow) {
                    try {
                        shadowRoot = hostNode.attachShadow({ mode: 'open' });
                    } catch (error) {
                        debug.warn('failed to attachShadow', { hostId, frameId, error, hostNode });
                    }
                }
                if (shadowRoot) {
                    reg.addNode(frameId, shadowRoot, nodeId);
                    RenderUtils.debugShadowRoot(shadowRoot);
                    newShadowRootNodeIds.push(nodeId);
                }
            } else {
                debug.warn('missing host node for shadowRoot', { hostId, frameId });
            }
        });

        // reorder nodes
        forEach(dom, (data, id) => {
            if (data && data[F_NODE.CHILDREN] !== undefined) {
                const parentNode = reg.getNode(frameId, id);
                if (parentNode === undefined) {
                    debug.recordWarning('missing node for F_NODE.CHILDREN', { id, data, dom });
                    return;
                }

                // style content rendering handled separately
                if (DOM.isElementOneOf(parentNode, DOM.TAG_STYLE)) {
                    return;
                }

                let fragment = frame.doc.createDocumentFragment();

                let i = data[F_NODE.CHILDREN].length;
                let prevInsertedNode = null;
                while (i) {
                    i -= 1;
                    const nodeId = data[F_NODE.CHILDREN][i];
                    const node = reg.getNode(frameId, nodeId);
                    if (node) {
                        const nextNodeId = data[F_NODE.CHILDREN][i + 1];
                        let nextNode = null;
                        if (nextNodeId) {
                            nextNode = reg.getNode(frameId, nextNodeId);
                            if (!nextNode) {
                                debug.warn(`Next node#${nextNodeId} not found, parent#${id}, frame#${frameId}`);
                            }
                        }

                        if ((node.parentNode !== parentNode) || (dom[nodeId] && dom[nodeId][F_NODE.IS_MOVED])) {
                            RenderUtils.insertBefore(node, fragment, nextNode === prevInsertedNode ? null : nextNode);
                        } else {
                            if (fragment.firstChild) {
                                RenderUtils.insertBefore(fragment, parentNode, prevInsertedNode);
                                fragment = frame.doc.createDocumentFragment();
                            }
                            prevInsertedNode = node;
                        }
                    } else {
                        debug.warn(`Child node#${nodeId} node not found, parent#${id}, frame#${frameId}`);
                    }
                }

                RenderUtils.insertBefore(fragment, parentNode, prevInsertedNode);
            }
        });

        // browser keeps adding <body> to document with <frameset>
        if (!reg.getNodeId(frame.doc.body)) {
            DOM.removeNode(frame.doc.body);
        }

        valueWatcher.restoreAllNodes();
        checkedWatcher.restoreAllNodes();
        selectedWatcher.restoreAllNodes();
        this.emit(this.EVENT_AFTER_RENDER, frame, newElements, deletedElementUids, newShadowRootNodeIds);
    }

    createNode(frame, data) {
        if (data[F_NODE.CLIENT_ID] !== undefined) {
            const node = reg.popClientNode(frame.id, data[F_NODE.CLIENT_ID]);
            if (node && node.nodeValue === data[F_NODE.TEXT]) {
                return node;
            }
        }

        if (data[F_NODE.TEXT] !== undefined) {
            return frame.doc.createTextNode(data[F_NODE.TEXT]);
        }

        if (data[F_NODE.TAG] !== undefined) {
            let namespaceURI;
            if (data[F_NODE.TAG_NS] !== undefined) {
                namespaceURI = frame.dict[data[F_NODE.TAG_NS]];
                if (namespaceURI === undefined) {
                    debug.recordWarning('missing namespace name for namespaceId', {
                        data,
                        dict: frame.dict,
                    });
                }
            }

            const tagName = namespaceURI === undefined
                ? decodeNodeType(data[F_NODE.TAG])
                : data[F_NODE.TAG];

            // reuse documentElement, head and body
            let reusedNode;
            if (tagName === DOM.normalizeTagName(frame.doc.documentElement)) {
                reusedNode = frame.doc.documentElement;
            }

            if (tagName === DOM.TAG_HEAD && frame.doc.head) {
                reusedNode = frame.doc.head;
            }

            if (tagName === DOM.TAG_BODY && frame.doc.body) {
                reusedNode = frame.doc.body;
            }

            if (reusedNode) {
                if (reg.getNodeId(reusedNode) === undefined) {
                    return reusedNode;
                } else {
                    debug.warn('node is already in use', {
                        id: reg.getNodeId(frame.doc.body),
                        data,
                    });
                }
            }

            const isMediaNode = (tagName === DOM.TAG_VIDEO) || (tagName === DOM.TAG_AUDIO);

            let node;
            try {
                if (namespaceURI !== undefined) {
                    node = frame.doc.createElementNS(namespaceURI, tagName);
                } else {
                    node = frame.doc.createElement(tagName);
                }
            } catch (e) {
                node = frame.doc.createElement(INVALID_TAG_NAME);
                node.__originalTagName = tagName;
            }
            if (tagName === DOM.TAG_A) {
                // set default link target to _top, it might be overwritten with _blank
                // specifically for IE context menu "open"
                node.target = '_top';
            } else if (tagName === DOM.TAG_STYLE) {
                // introduce new <style> nodes into the DOM to preserve styleSheets order
                // fixes inverse style order in the Edge
                if (frame.doc && frame.doc.documentElement) {
                    frame.doc.documentElement.appendChild(node);
                }
            } else if (isMediaNode) {
                fixMedia(node);
            }

            return node;
        }
    }

    couldSetSelection() {
        if (IS_TOP) {
            return true;
        }

        try {
            return !(window.frameElement && window.frameElement.dataset.disableFocus);
        } catch (e) { // TODO: possibly cannot access frameElement from different domain
            return true;
        }
    }

    checkTitle() {
        const currentTitle = document.title || this.location;
        if (currentTitle !== this.title) {
            this.title = currentTitle;
            messageToBrowser(API_CLIENT_TO_BROWSER.setTitle, { title: this.title });
        }
    }

    replaceLocation = (newLocationValue) => {
        // TODO: fix about:blank pages
        if (!newLocationValue) {
            return;
        }

        this.location = newLocationValue;

        const oldHash = location.hash;
        history.replaceState({}, '', makeReadablePageUrl(newLocationValue));
        // Firefox and Chrome bug: https://bugs.webkit.org/show_bug.cgi?id=83490
        // history.push/replaceState doesn't trigger :target CSS selector
        let hash = location.hash;
        if (hash !== oldHash) {
            if (hash[0] !== '#') {
                hash = `#${hash}`;
            }
            const { pageXOffset, pageYOffset } = window; // preserve scroll position
            location.replace(`${hash}_`); // trigger change
            location.replace(hash);
            window.scrollTo(pageXOffset, pageYOffset); // scrolling to the current position does not trigger events
        }

        this.checkTitle();

        debug.addDebugAttribute(document, 'weblife-url', document.location.href);
    };

    /** @type DLPSettings */
    dlp = new DLPSettings();
    setDLP = (settings) => {
        if (!settings) {
            return;
        }

        let changed = false;
        Object.entries(settings).forEach(([key, value]) => {
            if (this.dlp[key] !== value) {
                this.dlp[key] = value;
                changed = true;
            }
        });

        if (changed) {
            valueWatcher.updateInputRestrictions(this.dlp);
            messageToBrowser(API_CLIENT_TO_BROWSER.setDLP, this.dlp);
        }
    };
}

const page = new ClientPage();

export default page;
